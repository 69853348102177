





























































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Prop, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { getComponent } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { integer } from 'aws-sdk/clients/cloudfront';
import proceduresModule from '@/store/modules/proceduresModule';
import {
  CHART_CLICK_EVENT, ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE,
} from '@/lib/constants';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfoV2: () => getComponent('tasqs/SetpointFeedbackInfoV2'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    LineChart: () => import('@/lib/charts/lineChart'),
    Multiselect,
  },
})
export default class TasqFeedbackResponseSection extends mixins(DataLoading) {

    @Prop({ type: Object, required: false }) tasqResponseData?: any;

	activityShown = 'history';
	showingCommentIDs: string[] = []

  	showSetpointWellHistoryDetailsPopup = false
  	setpointDetailsWellHistory = null

  	isSearchingProcedureOptions = false

	isSavingProcedureStep = false

	showMobileOptionDetailsPopup = false

  	searchingProcedureText: string = ''

	comment: string = ''


	testing() {
		console.log(this.comment)
		
		tasqFeedbackModule.setInnerCommentVal(this.comment)
	}


  windowWidth = window.innerWidth;

    get isMobile() {
     return this.windowWidth < 769;
   }


   showDetailsMobile() {
	   this.showMobileOptionDetailsPopup = true
   }
   hideDetailsMobile() {
	   this.showMobileOptionDetailsPopup = false
   }


	optionIsSelected(option) {
		if (this.stepper == 2) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text
			if (tasqFeedbackModule.systemsSelected.includes(check_string)) {
				return true
			}
			return false
		} else if (this.stepper == 3) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text
			if (tasqFeedbackModule.symptomsSelected.includes(check_string)) {
				return true
			}
			return false
		} else if (this.stepper == 4) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text
			if (tasqFeedbackModule.causesSelected.includes(check_string)) {
				return true
			}
			return false
		} else if (this.stepper == 5) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text
			if (tasqFeedbackModule.actionsSelected.includes(check_string)) {
				return true
			}
			return false
		}
	}

	selectOption(option, skipOnMobile = false) {
		if (this.isMobile && skipOnMobile) {
			return
		}
		if (this.stepper == 2) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text

			if (tasqFeedbackModule.systemsSelected.includes(check_string)) {
				const index = tasqFeedbackModule.systemsSelected.indexOf(check_string);
				if (index > -1) {
					// tasqFeedbackModule.systemsSelected.splice(index, 1);
					tasqFeedbackModule.removeSystemsSelected(index)
				}
			} else {
				tasqFeedbackModule.pushSystemsSelected(check_string)
			}
		} else if (this.stepper == 3) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text

			if (tasqFeedbackModule.symptomsSelected.includes(check_string)) {
				const index = tasqFeedbackModule.symptomsSelected.indexOf(check_string);
				if (index > -1) {
					tasqFeedbackModule.removeSymptomsSelected(index)
					// tasqFeedbackModule.symptomsSelected.splice(index, 1);
				}
			} else {
				tasqFeedbackModule.pushSymptomsSelected(check_string)
			}
		} else if (this.stepper == 4) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text

			if (tasqFeedbackModule.causesSelected.includes(check_string)) {
				const index = tasqFeedbackModule.causesSelected.indexOf(check_string);
				if (index > -1) {
					tasqFeedbackModule.removeCausesSelected(index)
					// tasqFeedbackModule.causesSelected.splice(index, 1);
				}
			} else {
				tasqFeedbackModule.pushCausesSelected(check_string)
			}
		} else if (this.stepper == 5) {
			var check_string = option.text
			check_string = option.title + "<>" + option.text

			if (tasqFeedbackModule.actionsSelected.includes(check_string)) {
				const index = tasqFeedbackModule.actionsSelected.indexOf(check_string);
				if (index > -1) {
					// tasqFeedbackModule.actionsSelected.splice(index, 1);
					tasqFeedbackModule.removeActionsSelected(index)
				}
			} else {
				tasqFeedbackModule.pushActionsSelected(check_string)
			}
		}
	}


	optionGroupsAvailable(optionsGroup) {
		if (this.stepper == 2) {
			return optionsGroup.systems
		} else if (this.stepper == 3) {
			return optionsGroup.symptoms
		} else if (this.stepper == 4) {
			return optionsGroup.causes
		} else if (this.stepper == 5) {
			return optionsGroup.actions
		}
	}


	get optionsAvailable() {
		if (this.stepper == 2) {
			return tasqFeedbackModule.systemOptionsAvailable
		} else if (this.stepper == 3) {
			return tasqFeedbackModule.symptomOptionsAvailable
		} else if (this.stepper == 4) {
			return tasqFeedbackModule.causeOptionsAvailable
		} else if (this.stepper == 5) {
			return tasqFeedbackModule.actionOptionsAvailable
		}
		
	}

	get stepper() {
		return tasqFeedbackModule.stepper
	}


	created() {
		this.comment = ''
		if (tasqFeedbackModule.stepper == 1) {
			this.comment = tasqFeedbackModule.validateComment
		} else if (tasqFeedbackModule.stepper == 2) {
			this.comment = tasqFeedbackModule.systemsComment
		} else if (tasqFeedbackModule.stepper == 3) {
			this.comment = tasqFeedbackModule.symptomsComment
		} else if (tasqFeedbackModule.stepper == 4) {
			this.comment = tasqFeedbackModule.causesComment
		} else if (tasqFeedbackModule.stepper == 5) {
			this.comment = tasqFeedbackModule.actionsComment
		}

		this.setCommentText()
		
	}
	


  get dropdownResponseLabel() {
	  if (this.stepper == 1) {
		  return this.identifyingText
	  } else if (tasqFeedbackModule.stepper == 2) {
			return "What systems are showing an issue?"
		} else if (tasqFeedbackModule.stepper == 3) {
			return "What symptoms do you see?"
		} else if (tasqFeedbackModule.stepper == 4) {
			return "What caused the issue?"
		} else if (tasqFeedbackModule.stepper == 5) {
			return "What action are you taking today?"
		}
  }



  get identifyingText() {
    // if (this.tasq.completed) return 'This tasq has been closed'
    if ((this.tasq || {}).noComms) return 'Has this been resolved?';
    if ((this.tasq || {}).wellTestData) return 'Has this well test been completed?';
    if (this.tasq.engineerType === this.$getConst('TASQ_WORKFLOW_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')
      || this.tasq.predictionType === 'Reject this'
    ) return 'What action was taken?';
    if ((this.tasqResponseData || {}).ResponseData) return 'Please update your feedback:';
    if (this.tasq.engineerType != null && this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
      return 'Is this well off target?';
    }
    if (this.tasq.isManuallyAdded) {
      return this.tasq.predictionType;
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_PREDICTION_TYPE')) {
      return 'What would you like to do?';
    }
    return this.tasq.engineerType === 'issue' || !this.tasq
      ? 'Is there an issue here?' : `Did Tasq correctly identify the ${this.tasq?.engineerType}?`;
  }




	// Version of json s3 file
	get jsonVersion() {
		return proceduresModule.proceduresJsonVersion
	}


	get currentWellType(): any {
		if(assetsModule.activeTasq){
		return 'producing'
		}
		return this.$route.query.type;
	}


	get tasq() {
		if (tasqsListModule.isBatchResponding) {
		if(!tasqsListModule.activeTasq) {
			tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
		}
			return tasqsListModule.activeTasq as TasqJob;
		}
		if (this.currentWellType == 'producing') {
		return assetsModule.activeTasq as TasqJob;
		}
		return tasqsListModule.activeTasq as TasqJob;
	}

	toTitleCase(str) {
		return str.replace(/\w\S*/g, function(txt){
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}


	formatBubbleText(val) {
		return this.toTitleCase(val.toLowerCase())
	}


	filterBubbleValue(val) {

		if (this.searchingProcedureText !== '' && this.isSearchingProcedureOptions) {
			if (val.toLowerCase().startsWith(this.searchingProcedureText.toLowerCase())) {
				return false
			}
			return true
		}
		if (val == '') {
			return true
		}
		return false
		
	}




  async handleStep(selectionAction, markAsComplete = false) {

	  	if (selectionAction != null) {
			  tasqFeedbackModule.setSelectionAction(selectionAction.value)
		  }
		

		this.isSavingProcedureStep = true


		tasqFeedbackModule.setFeedbackSteps(tasqFeedbackModule.stepper)


		if (tasqFeedbackModule.stepper == 1) {
			tasqFeedbackModule.setValidateComment(this.comment)
		} else if (tasqFeedbackModule.stepper == 2) {
			tasqFeedbackModule.setSystemsComment(this.comment)
		} else if (tasqFeedbackModule.stepper == 3) {
			tasqFeedbackModule.setSymptomsComment(this.comment)
		} else if (tasqFeedbackModule.stepper == 4) {
			tasqFeedbackModule.setCausesComment(this.comment)
		} else if (tasqFeedbackModule.stepper == 5) {
			tasqFeedbackModule.setActionsComment(this.comment)
		}

		var leaveFeedback = {
			tasq: this.tasq,
			validate: tasqFeedbackModule.selectionAction,
			validateComment: tasqFeedbackModule.validateComment,
			systems: tasqFeedbackModule.systemsSelected,
			systemsComment: tasqFeedbackModule.systemsComment,
			symptoms: tasqFeedbackModule.symptomsSelected,
			symptomsComment: tasqFeedbackModule.symptomsComment,
			causes: tasqFeedbackModule.causesSelected,
			causesComment: tasqFeedbackModule.causesComment,
			actions: tasqFeedbackModule.actionsSelected,
			actionsComment: tasqFeedbackModule.actionsComment,
			proceduresJSONVersion: this.jsonVersion,
		}
		console.log(leaveFeedback)

		if (markAsComplete) {
			// @ts-ignore
			leaveFeedback.markAsComplete = true
		}

		await tasqFeedbackModule.leaveFeedbackV2(leaveFeedback);

		this.isSavingProcedureStep = false
		


		if (markAsComplete) {
			this.isSavingProcedureStep = false
			this.$router.push({ name: 'Tasqs' });
			await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
			this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');

		
		}
		this.setCommentText()
		tasqFeedbackModule.setupPage()

	  

  }

  setCommentText() {
	  if (this.stepper == 1) {
		  this.comment = tasqFeedbackModule.validateComment
	  } else if (this.stepper == 2) {
		  this.comment = tasqFeedbackModule.systemsComment
	  } else if (this.stepper == 3) {
		  this.comment = tasqFeedbackModule.symptomsComment
	  } else if (this.stepper == 4) {
		  this.comment = tasqFeedbackModule.causesComment
	  } else if (this.stepper == 5) {
		  this.comment = tasqFeedbackModule.actionsComment
	  }

  }

}



